<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['products'])" />
  <div v-else>
    <div v-for="category in categories" :key="category.id">
      <div
        class="catalog__category-label"
        :style="
          `margin-top: ${category.topMargin ? category.topMargin : '1rem'}; ${
            breakBeforeCategories.includes(category.id) ? 'page-break-before: always;' : ''
          }`
        "
      >
        {{ category.label }}
      </div>
      <ul class="productlist">
        <li v-for="product in products(category)" :key="product.id">
          <div class="product" v-if="!product.hideFromCatalog">
            <div
              class="product__img"
              :style="
                `background-image: url(https://storage.googleapis.com/emma-static/products/${product.img}_1200.jpg)`
              "
            />
            <div class="product__meta">
              <div class="product__meta__product-id">{{ product.id }}</div>
              <div class="product__meta__title">
                {{ productTitle(product) }},
                <span style="white-space: nowrap">{{ product.packageSize }} {{ product.packageUnit }}</span>
              </div>
              <div class="product__meta__price">
                <span>{{ product.retailPrice.toString().split('.')[0] }}</span>
                <span>,</span>
                <sup class="product__meta__price__cent">
                  <span v-if="product.retailPrice.toString().split('.')[1]"
                    >{{ product.retailPrice.toString().split('.')[1]
                    }}{{ product.retailPrice.toString().split('.')[1].length === 1 ? '0' : '' }}</span
                  >
                  <span v-else>00</span>
                </sup>
                €
              </div>
              <div class="product__meta__container-deposit" v-if="product.containerDeposit">
                + {{ formatCurrency(product.containerDeposit) }} Pfand
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import catalogJSON from '@/data/catalog.json'
import { CalculateCatalog } from '@/helpers/CalculateCatalog'

export default {
  data() {
    return {
      breakBeforeCategories: ['AAD', 'ADC', 'AFC', 'BAB', 'BF', 'D', 'EA', 'EB', 'EC', 'H'],
      hideCategories: ['AG', 'BAE', 'F', 'I']
    }
  },
  computed: {
    categories() {
      const catalog = CalculateCatalog(JSON.parse(JSON.stringify(catalogJSON)), {
        flat: true,
        products: this.$store.state.products
      })
      catalog.categories = catalog.categories.filter(c => c.products.length > 0 && !this.hideCategories.includes(c.id))
      return catalog.categories
    }
  },
  methods: {
    products: function(category) {
      const products = _.sortBy(_.filter(this.$store.state.products, { categoryId: category.id, live: true }), 'order')
      return products
    },
    productTitle(product) {
      const labels = [product.brandName, product.label, product.additionalLabel]
      return labels
        .filter(label => {
          return typeof label === 'string' && label !== ''
        })
        .join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.catalog {
  &__category-label {
    margin: 1rem 0 0.5rem;
    padding: 0.5rem 1rem 0.5rem 2rem;

    background: $color-main;
    border-radius: 5px;

    color: #fff;
    font-size: 1.25rem;
    font-weight: $weight-bold;
  }
}

.productlist {
  @extend %cleanlist;

  display: table;
  width: 100%;
  margin: 0 0 0 2rem;
}

.product {
  width: 50%;
  margin: 1rem 0 0.75rem;
  display: flex;
  align-items: center;
  float: left;
  height: 140px;

  &__img {
    width: 25%;
    margin-right: 1rem;

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
  &__meta {
    width: 50%;

    &__title {
      font-size: 1.25rem;
      font-weight: $weight-bold;
    }

    &__product-id {
      color: $color-main;
      font-weight: bold;
      font-size: 1.25rem;
    }

    &__price {
      font-size: 1.5rem;
      font-weight: $weight-bold;
      text-align: right;
    }

    &__container-deposit {
      text-align: right;
      font-size: 0.875rem;
    }
  }
}

@media print {
  .catalog {
    &__category-label {
      page-break-inside: avoid;
      page-break-after: avoid;
    }
  }
  .product {
    page-break-inside: avoid;
  }
}
</style>
